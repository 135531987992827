import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class ProgressBar {
    constructor() {
        this.init();
    }

    init() {
        const progressBar = document.querySelector('#progress-bar');
        const progress = progressBar.querySelector('progress');

        gsap.to(progress, {
            value: 100,
            ease: 'none',
            scrollTrigger: { 
                scrub: 0.3 }
        });
    }

}
