import { gsap, Power4 } from "gsap";

let _root;

export default class SectionBotanicals {
    constructor() {
        this.init();

        window.addEventListener('resize', () => this.onResize());
    }

    onResize() {
        this.init();
    }

    init() {

        _root = this;

        const _botanicals = document.querySelector('.botanicals');
        const element = _botanicals.querySelectorAll('.botanicals--item');

        _root.bowls = document.querySelector('.section-botanicals__bowl');


        if ('undefined' != typeof element && null != element) {

            element.forEach(item => {
                _root.addEvent(item, 'mouseenter', _root.onItemMouseOver);
            });

        }

    }

    onItemMouseOver(e) {

        const _id = e.target.getAttribute('data-id');

        _root.bowls.querySelectorAll('.bowl').forEach(bowl => {
                
            gsap.set(bowl, { display: "none" });
            const trg = _root.bowls.querySelector(`.bowl-${CSS.escape(_id)}`);
            gsap.set(trg, { display: "block", opacity: 0, x: '100%' });
            gsap.to(trg, { duration: 1, autoAlpha: 1, x: 0, ease: Power4.easeInOut });

        });

    }

    addEvent(el, type, handler) {
        if (el.attachEvent) el.attachEvent('on' + type, handler);
        else el.addEventListener(type, handler);
    }

    removeEvent(el, type, handler) {
        if (el.detachEvent) el.detachEvent('on' + type, handler);
        else el.removeEventListener(type, handler);
    }

}
