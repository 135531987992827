import SectionHero from "../sections/home/section-hero";
import SectionService from "../sections/home/section-service";
import SectionBotanicals from "../sections/home/section-botanicals";
import SectionLifestyle from "../sections/home/section-lifestyle";

export default class PageHome {
    constructor(root) {
        this.init();
    }

    init() {
        new SectionHero();
        new SectionService();
        new SectionBotanicals();
        new SectionLifestyle();
    }
}
