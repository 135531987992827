import { gsap } from "gsap";
import textAppear from './text-appear';

let _isStarted = true;
let _countLoadedImages = 0;
let _allImages = 0;
let imagesLoaded = require('imagesLoaded');
let _tweenInc;
let _preloader;
let _preloaderLogo;
let _preloaderProgress;
let _preloaderBar;
let _tlProgress;
let _root;
let _textAppear;

export default class Preloader {
    constructor() {
        _root = this;
        _textAppear = new textAppear();
        this.init();
    }

    init() {
        _preloader = document.getElementById('preloader');
        _preloaderLogo = _preloader.querySelector('.preloader-logo');
        _preloaderProgress = _preloader.querySelector('.preloader-progress');
        _preloaderBar = _preloader.querySelector('.preloader-bar');

        let _imagesLoaded = imagesLoaded(document.querySelectorAll('.img-preloader'), { background: 'div' });
        _allImages = _imagesLoaded.images.length;
        _imagesLoaded.on('always', _root.onAlways);
        _imagesLoaded.on('progress', _root.onProgress);

    }

    animationStart() {
        _tlProgress = new gsap.timeline({ delay: 1, onStart: _root.onInitStart, onComplete: _root.onInitComplete });

        _tlProgress.set(_preloader, { display: 'block', autoAlpha: 1 });
        _tlProgress.to(_preloader, 0.3, { autoAlpha: 0 });

        _tlProgress.set(_preloaderLogo, { display: 'block', autoAlpha: 1 });
        _tlProgress.to(_preloaderLogo, 0.3, { autoAlpha: 0 });

        _tlProgress.set(_preloaderProgress, { display: 'block', autoAlpha: 1 });
        _tlProgress.to(_preloaderProgress, 0.3, { autoAlpha: 0 });
    }

    onInitComplete() {
        if (_isStarted) {
            _isStarted = false;
        }

        _textAppear.init();
    }

    onInitStart() {
        //_root._textAppear.init();

        //alert("ok");
    }

    onAlways(instance) {
        _root.animationStart();
    }

    progressUpdate() {
        let _width = Math.round(_tweenInc.progress() * 100);
        _preloaderProgress.textContent = _width + '%';
    }

    onProgress(instance, image) {

        if (image.isLoaded) {
            _countLoadedImages++;
        }

        _tweenInc = gsap.to(_preloaderBar, {
            duration: 0.5,
            width: "100%",
            onUpdate: _root.progressUpdate,
            ease: gsap.Linear
        });
    }

}
