import { gsap, Power4 } from 'gsap';
import 'youtube-video-js';


let _root;
let _tl;
let _overlay;
let _overlayBg;
let _videoContainer;
let _overlayClose;
let _menuIsVisible = false;

export default class OverlayVideo {
    constructor(root) {
        _root = this;
        _overlay = document.querySelector('#overlay-video');
        _overlayBg = _overlay.querySelector('.overlay-video__bg');
        _overlayClose = _overlay.querySelector('.overlay-video__close');
        _videoContainer = _overlay.querySelector('.overlay-video--yt__inner');

        this.initButton();

        this.setOutsideClick();

    }

    initButton() {
        const element = document.querySelector('.btn-video');

        if ('undefined' != typeof element && null != element) {
            _root.addEvent(element, 'click', _root.onClickMenuEvent);
        }
    }

    onClickMenuEvent(el) {
        el.preventDefault();

        _tl = gsap.timeline({ onComplete: _root.onCompleteTimelineLite, onReverseComplete: _root.reverseFunction });

        _root.unloadScrollBars();

        _tl.set(_overlay, { display: 'block' });
        _tl.set(_overlayBg, { display: 'block', width: 0 });

        _tl.set(_overlayBg, {transformOrigin: 'top right'});

        _tl.to(_overlayBg, 0.5, { width: '100%', transformOrigin: 'top right', ease: Power4.easeInOut });

        _tl.set(_videoContainer, { display: 'block', autoAlpha: 0 });
        _tl.to(_videoContainer, 0.5, { autoAlpha: 1 });

        _tl.set(_overlayClose, { display: 'block', autoAlpha: 0 });
        _tl.to(_overlayClose, 0.5, { autoAlpha: 1 });

        _root.removeEvent(document.querySelector('.btn-video'), 'click', _root.onClickMenuEvent);
    }

    setOutsideClick() {
        document.addEventListener('click', (event) => {
            const withinBoundaries = event.composedPath().includes(_overlay)
            if ( !withinBoundaries ) {
                if (_menuIsVisible) {
                    _tl.reverse();
                }
            } 
        });
    }

    reloadScrollBars() {
        document.documentElement.style.overflow = 'auto';
        document.body.scroll = 'yes';
    }

    unloadScrollBars() {
        document.documentElement.style.overflow = 'hidden';
        document.body.scroll = 'no';
    }

    onCompleteTimelineLite() {
        _root.addEvent(_overlayClose, 'click', _root.onClickCloseEvent);

        document.querySelector('youtube-video').play();

        _menuIsVisible = true;

        document.querySelectorAll('#mobile-menu .menu-item').forEach(_item => {
            _root.addEvent(_item.querySelector('a'), 'click', _root.onClickMenuItem);
        });
    }

    onClickMenuItem(el) {
        _tl.reverse();
    }

    reverseFunction() {
        _root.reloadScrollBars();
        _root.initButton();
        document.querySelector('youtube-video').pause();
        _menuIsVisible = false;
    }

    onClickCloseEvent(el) {
        el.preventDefault();
        _tl.reverse();
    }

    addEvent(el, type, handler) {
        if (el.attachEvent) el.attachEvent('on' + type, handler);
        else el.addEventListener(type, handler);
    }

    removeEvent(el, type, handler) {
        if (el.detachEvent) el.detachEvent('on' + type, handler);
        else el.removeEventListener(type, handler);
    }
}