import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import Splitting from "splitting";

export default class SectionService {
    constructor() {
        this.init();
        

        setTimeout(() => {
            this.startMoveElements();
        }, 1000);
    }

    init() {
        Splitting();
        const fx16Titles = [...document.querySelectorAll('.section-text[data-splitting][data-effect16]')];

        const scroll = () => {
    
            fx16Titles.forEach(title => {
                
                gsap.fromTo(title, {
                    transformOrigin: '50% 50%',
                    scale: 1,
                }, {
                    ease: 'none',
                    scale: 1,
                    scrollTrigger: {
                        trigger: title,
                        start: 'top bottom',
                        end: 'top top',
                        scrub: true,
                    }
                });
        
                gsap.fromTo(title.querySelectorAll('.word'), {
                    'will-change': 'opacity',
                    opacity: 0.1
                }, 
                {
                    ease: 'none',
                    opacity: 1,
                    stagger: 0.05,
                    scrollTrigger: {
                        trigger: title,
                        start: 'top bottom-=40%',
                        end: 'center top+=40%',
                        scrub: true,
                    }
                });
        
            });
        
        };

        scroll();
    }

    startMoveElements() {

        let element = document.querySelectorAll('.parallax-item');

        if ('undefined' != typeof element && null != element) {

            gsap.utils.toArray(".parallax-item").forEach(layer => {

                if (layer.dataset.scroll != null) {

                    const tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: layer.parentElement,
                            scrub: true
                        }
                    });

                    let speed = 1;
                    let direction = 'vertical';
                    let movement = -(layer.offsetHeight * speed);

                    if (layer.dataset.speed) {
                        speed = layer.dataset.speed;
                    }

                    if (layer.dataset.direction) {
                        direction = layer.dataset.direction;
                    }

                    if (direction == "vertical") {
                        movement = -(layer.offsetHeight * speed);
                        tl.to( layer, { y: movement, ease: "none" }, 0 );
                    }

                    if (direction == "horizontal") {
                        movement = -(layer.offsetWidth * speed);
                        tl.to( layer, { x: movement, ease: "none" }, 0 );
                    }

                    if (direction == "rotate") {
                        tl.to(
                            layer, {
                                rotation: speed,
                                transformOrigin: "center center",
                                ease: "none"
                            }, 0);
                    }

                }

            });

        }

    }
}
